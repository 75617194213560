<template>
  <div>
    <div class="lesson-title">
      <el-image class="lesson-title-img" :src="courseData.image" fit="cover" />
      <div class="lesson-info" style="position: relative">
        <div
          style="display: flex; flex-direction: column; align-items: flex-start"
        >
          <div>
            <span class="vip-tag">会员</span>
            <span class="m-lesson-name">{{ courseData.name }}</span>
          </div>
          <span class="lesson-des">{{ courseData.summary }}</span>
        </div>
        <el-popover
          placement="bottom"
          width="200"
          trigger="manual"
          v-model="visible"
          style="position: absolute; right: 0"
        >
          <el-image style="width: 1rem; height: 1rem" :src="qrCode" />
          <div
            slot="reference"
            @click="visible = !visible"
            class="watch-in-phone-layout"
          >
            <img class="phone-icon" src="../assets/shouji.png" />
            <span class="watch-in-phone clc">手机观看</span>
          </div>
        </el-popover>
      </div>
    </div>
    <div class="lesson-list-layout">
      <div>
        <el-tabs v-model="activeName">
          <el-tab-pane label="目录" name="first">
            <div
              class="lesson-item"
              v-for="(item, index) in videoList"
              :key="index"
            >
              <div class="lesson-item-title">
                <span class="lesson-item-title-number">{{ index + 1 }}</span>
                <span class="lesson-item-title-name">
                  {{ courseData.name }}
                </span>
              </div>

              <div class="play-l clc" @click="playLesson(item.id)">
                <img class="play-l-icon" src="../assets/bofang.png" />
                <span class="play-l-name">{{ item.name }}</span>
                <span class="play-l-time">{{ formatSeconds(item.time) }}</span>
              </div>
            </div>

            <div class="more-layout">
              <template v-if="!this.$store.state.hasLogin">
                <span class="more-layout-tip">
                  需要登录后才可查看剩余内容,请从上方点击注册进行观看
                </span>
              </template>
            </div>
          </el-tab-pane>

          <el-tab-pane label="提问" name="third" class="question">
            <div class="question-layout flex-div">
              <div class="flex-div-h question-course-complexity">
                <span class="course-complexity-title">难易度程度：</span>
                <div
                  class="flex-div-h child-in-center course-complexity-item"
                  v-for="(item, index) in courseComplexity"
                  :key="index"
                >
                  {{ item }}
                </div>
              </div>
              <el-input
                class="question-input"
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 8 }"
                placeholder="请提出您对于内容中感到疑惑的地方吧！"
                v-model="questionContent"
                maxlength="2000"
                show-word-limit
              ></el-input>
              <div class="flex-div-h submit-question">发表提问</div>
              <div class="line match-parent"></div>
              <!-- 问题列表 -->
              <div
                class="flex-div match-parent"
                v-for="(item, index) in 2"
                :key="index"
              >
                <div class="flex-div-h match-parent question-list-item">
                  <img
                    class="question-icon"
                    style="background-color: #495aff"
                    src="../assets/no-history.png"
                  />
                  <div class="flex-div question-item-info">
                    <span class="question-item-id">1541513630</span>
                    <div class="flex-div-h question-item-complexity">
                      有点难
                    </div>
                    <div
                      class="flex-div-h child-in-center question-item-question"
                    >
                      <span class="item-question-wen">问</span>
                      <span>有安装包么</span>
                    </div>
                    <div class="flex-div-h match-parent time-reply-wrapper">
                      <span class="question-time" style="flex: 1">3天前</span>
                      <span class="question-reply">回复</span>
                      <img
                        class="question-point"
                        src="../assets/point-vertical.png"
                      />
                    </div>
                    <!-- 回复列表 -->
                    <div
                      class="flex-div-h match-parent reply-wrapper"
                      v-for="(item, index) in 3"
                      :key="index"
                    >
                      <img
                        class="reply-icon"
                        style="background-color: #495aff"
                        src="../assets/no-history.png"
                      />
                      <div class="flex-div reply-info">
                        <div class="flex-div-h">
                          <span class="reply-info-replier">老师</span>
                          <span class="reply-info-dianping flex-div-h">
                            点评
                          </span>
                          <span class="reply-info-huifu">回复</span>
                          <span class="reply-info-replier">1541513...</span>
                          <span class="reply-info-content">
                            软件涉及版权问题，不提供的，自行网上搜索下载，继续加油
                          </span>
                        </div>
                        <div class="flex-div-h match-parent time-reply-wrapper">
                          <span class="question-time">3天前</span>
                          <div style="flex: 1" class="huifu-wapper">
                            <span class="question-reply">回复</span>
                          </div>
                          <img
                            class="question-point"
                            src="../assets/point-vertical.png"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="line match-parent item-line"></div>
                  </div>
                </div>
              </div>
              <!-- 带有背景色的分页 -->
              <el-pagination
                background
                layout="prev, pager, next"
                :total="100"
                class="center-in-parent pagination"
              ></el-pagination>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "lesson",
    data() {
      return {
        // by yu
        courseComplexity: ["太简单", "简单", "适中", "有点难", "太难了"], // 内容复杂程度
        questionContent: "", // 提问的内容

        activeName: "first",
        hasLogin: false,
        visible: false,
        courseId: "",
        crumbData: {},
        courseData: {},
        videoDataList: [],
        videoList: [],
        qrCode: "",
      }
    },
    methods: {
      playLesson: function (id) {
        this.$router.push({
          name: "Play",
          query: {
            id: id,
          },
        })
      },
      requestData: async function () {
        let params = {
          id: this.courseId,
        }
        let res = await this.$request.postRaw("/course/detail", params)
        if (res.success) {
          this.qrCode = res.qrcode
          this.crumbData = res.crumb[0]
          this.courseData = res.data

          for (let i = 0; i < 20; i++) {
            this.videoDataList = res.data.video_list
          }

          if (!this.$store.state.hasLogin) {
            this.videoList = this.videoDataList.slice(0, 3)
          } else {
            this.videoList = this.videoDataList
          }
        } else {
          this.$util.warn(res.msg)
        }
      },
      formatSeconds(value) {
        let result = parseInt(value)
        let h =
          Math.floor(result / 3600) < 10
            ? "0" + Math.floor(result / 3600)
            : Math.floor(result / 3600)
        let m =
          Math.floor((result / 60) % 60) < 10
            ? "0" + Math.floor((result / 60) % 60)
            : Math.floor((result / 60) % 60)
        let s =
          Math.floor(result % 60) < 10
            ? "0" + Math.floor(result % 60)
            : Math.floor(result % 60)

        let res = "("
        if (h !== "00") res += `${h}时`
        if (m !== "00") res += `${m}分`
        res += `${s}秒)`
        return res
      },
    },
    computed: {
      loginState: function () {
        return this.$store.state.hasLogin
      },
    },
    watch: {
      loginState(isLogin) {
        if (!isLogin) {
          this.videoList = this.videoDataList.slice(0, 3)
        } else {
          this.videoList = this.videoDataList
        }
      },
    },
    created() {
      let query = this.$route.query
      this.courseId = query.id
      this.requestData()
    },
    mounted() {
      document.getElementById("header-line").style.visibility = "visible"
    },
  }
</script>

<style scoped>
  @import "../css/lesson.css";
  @import "../css/question.css";
</style>
