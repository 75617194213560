import Vue from 'vue'
import Vuex from 'vuex'
import request from '../js/http'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        schoolId: null,
        hasLogin: null,
        chanelId:null
    },
    getters: {
        getSchoolId: function (state) {
            if (!state.schoolId) {
                state.schoolId = localStorage.getItem('school_id')
            }
            return state.schoolId
        }
    },
    mutations: {
        setSchoolId(state, id) {
            state.schoolId = id
        },
        setLoginState(state, isLogin) {
            state.hasLogin = isLogin
        },
        setChanelId(state, id){
            state.chanelId = id
        }
    },
    actions: {
        async initSchoolId({commit}) {

            let host = window.location.host
            if (host == 'localhost:8080') {
                host = 'test.boyameiyu.com'
            }
            let params = {
                domain: host
            }
            let res = await request.post('/system/check', params)
            if (res) {
                localStorage.setItem('school_id', res.school_id)
                commit('setSchoolId', res.school_id)
                return true
            } else {
                return false
            }

        }
    },
    modules: {}
})
