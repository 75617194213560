import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import request from "./js/http"
import util from "./js/util"
import userData from "./js/userCache"
import "./plugins/element.js"
import "../src/css/base.css"
import "lib-flexible"

import VueVideoPlayer from "vue-video-player"
Vue.use(VueVideoPlayer)

import hls from "videojs-contrib-hls"
Vue.use(hls)

Vue.config.productionTip = false
Vue.prototype.$request = request
Vue.prototype.$util = util
Vue.prototype.$userData = userData

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app")
