<template>
  <div class="flex-div">
    <span class="history-title">学习记录</span>
    <div class="match-parent flex-div-h history-wrapper" v-if="hasHistory">
      <div
        class="flex-div history-item"
        v-for="(item, index) in historyData"
        :key="index"
        :class="[index % 4 == 0 ? '' : 'item-margin']"
      >
        <el-image class="history-item-pic" :src="item.image"></el-image>
        <div class="flex-div history-item-info">
          <span class="history-item-title">{{ item.video_name }}</span>
          <el-progress
            class="history-item-progress"
            :percentage="item.progress"
            :show-text="false"
            :stroke-width="10"
            color="#096CE0"
          ></el-progress>
          <span class="history-item-learn">已学习{{ item.progress }}%</span>
          <span
            class="history-item-continue flex-div-h child-in-center clc"
            @click="goVideo(item.video_id)"
          >
            继续学习
          </span>
        </div>
      </div>
    </div>
    <div class="no-history-wrapper flex-div" v-else>
      <img class="no-history-img" src="../assets/no-history.png" />
      <span class="no-history-text">暂无找到学习记录</span>
    </div>
  </div>
</template>

<script>
  export default {
    name: "history",
    data() {
      return {
        hasHistory: true,
        historyData: [], // 学习记录数据
      }
    },
    created: async function () {
      this.getHistory()
    },
    methods: {
      getHistory: async function () {
        let res = await this.$request.postRaw("/my/studyHistory")
        if (res.success) {
          this.historyData = []
          this.historyData = [].concat(res.data)
          if (this.historyData.length > 0) {
            this.hasHistory = true
          } else {
            this.hasHistory = false
          }
        }
      },
      goVideo(videoId) {
        this.$router.push("/course/video/play?id=" + videoId)
      },
    },
  }
</script>

<style scoped>
  @import "../css/history.css";
</style>
