<template>
  <div style="display: flex; flex-direction: column">
    <div id="header-l" class="header-s">
      <div class="header" style="flex-direction: row">
        <!--        <img class="logoImg center-in-parent" src="../src/assets/logo-svg.svg">-->
        <el-image
          class="logoImg center-in-parent clc"
          :src="logoImg"
          @click="toHome"
        />
        <div class="center-in-parent" style="flex: 1; align-items: flex-start">
          <template v-if="showInput">
            <div class="hd">
              <img
                class="search-i center-in-parent"
                src="./assets/search-icon.png"
              />
              <input
                class="search-inp center-in-parent"
                type="text"
                placeholder="输入内容名称进行搜索"
                v-model="searchTxt"
                @keyup.enter="onSubmit"
              />
            </div>
          </template>
          <template v-else>
            <span
              class="cate-btn"
              v-for="(item, index) in navData"
              :class="[index != 0 ? 'cate-btn-m' : '']"
              :key="index"
              @click="changeChanel(item.channel_id)"
            >
              {{ item.title }}
            </span>
          </template>
        </div>
        <template v-if="showInput">
          <img
            class="clear-btn center-in-parent"
            src="../src/assets/clear-icon.png"
            @click="showSearchInput"
          />
        </template>
        <template v-else>
          <img
            class="clear-btn center-in-parent"
            src="../src/assets/search-icon.png"
            @click="showSearchInput"
          />
        </template>
        <img
          class="history-btn center-in-parent"
          src="../src/assets/history-icon.png"
          @click="goHistory"
        />
        <template v-if="this.$store.state.hasLogin">
          <el-dropdown
            class="center-in-parent"
            placement="bottom-start"
            @command="loginOut"
          >
            <el-avatar
              class="clc"
              style="margin-left: 0.3rem"
              :src="userImg"
            ></el-avatar>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="login-out">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
        <template v-else>
          <div
            class="center-in-parent"
            style="display: inline; margin-left: 0.3rem"
          >
            <div class="hd h-l-b child-in-center" @click="showLogin = true">
              <span class="h-l-b-txt center-in-parent">登录</span>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div
      id="header-line"
      style="
        width: 100%;
        height: 0.01rem;
        background-color: #e9e9e9;
        visibility: hidden;
      "
    ></div>
    <div
      id="page-router"
      style="
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        background-color: #f5f5f5;
        min-height: 100vh;
      "
    >
      <div class="page-con">
        <router-view @show-login="showLoginModel" />
      </div>
    </div>
    <el-dialog :visible.sync="showLogin" width="630px">
      <div class="login-layout">
        <div class="login-title-l">
          <span class="dl-txt">登录</span>
          <img class="sin-img end-in-parent" src="../src/assets/sign-in.png" />
        </div>
        <div class="login-input-layout">
          <img class="login-phone" src="../src/assets/login-phone.png" />
          <input
            class="phone-input"
            v-model="account"
            type="text"
            placeholder="用户名"
          />
        </div>
        <div class="login-input-line"></div>
        <div class="login-input-layout" style="margin-top: 44px">
          <img
            class="login-phone"
            style="height: 26px"
            src="../src/assets/mima.png"
          />
          <input
            class="phone-input"
            v-model="password"
            type="password"
            placeholder="密码"
          />
        </div>
        <div class="login-input-line"></div>
        <div class="flex-div-h match-parent login-help-l">
          <el-checkbox v-model="isSave">记住密码</el-checkbox>
          <el-link type="primary" @click="findPwd">忘记密码?</el-link>
        </div>
        <div class="login-in-btn flex-div child-in-center" @click="doLogin">
          <span class="login-in-btn-txt center-in-parent">登 录</span>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="showFind" width="600px">
      <div class="find-layout">
        <div class="flex-div-h">
          <span class="zhmm-txt">找回密码</span>
          <img class="zhmm-img end-in-parent" src="../src/assets/zhmm.png" />
        </div>
        <div class="login-input-layout">
          <img class="login-phone" src="../src/assets/login-phone.png" />
          <input class="phone-input" type="text" placeholder="手机号" />
        </div>
        <div class="login-input-line"></div>
        <div
          class="login-input-layout"
          style="margin-top: 30px; position: relative"
        >
          <img
            class="login-phone"
            style="height: 21px; width: 18px; margin-top: 4px"
            src="../src/assets/yzm.png"
          />
          <input
            class="phone-input"
            type="password"
            placeholder="输入短信验证码"
          />
          <span class="get-yzm" @click="sendSMS">{{ yzmTxt }}</span>
        </div>
        <div class="login-input-line"></div>
        <div class="login-input-layout" style="margin-top: 30px">
          <img
            class="login-phone"
            style="height: 26px"
            src="../src/assets/mima.png"
          />
          <input class="phone-input" type="password" placeholder="输入新密码" />
        </div>
        <div class="login-input-line"></div>
        <div class="login-input-layout" style="margin-top: 30px">
          <img
            class="login-phone"
            style="height: 26px"
            src="../src/assets/mima.png"
          />
          <input class="phone-input" type="password" placeholder="确认新密码" />
        </div>
        <div class="login-input-line"></div>
        <div class="find-pwd-btn flex-div child-in-center">
          <span class="find-pwd-btn-txt center-in-parent">修改密码</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: "app",
    data() {
      return {
        searchTxt: "",
        showInput: false,
        showLogin: false,
        showFind: false,
        isShowFind: false,
        yzmTxt: "获取短信验证码",
        timer: null,
        isSave: false,
        navData: [],
        account: "",
        password: "",
        userImg: "",
        logoImg: "",
      }
    },
    mounted() {
      if (navigator.userAgent.indexOf("Mobile") > -1) {
        let host = window.location.host
        if (host.indexOf(".m.") == -1) {
          let subdomain = host.substring(0, host.indexOf("."))
          let maindomain = host.substring(host.indexOf(".") + 1)
          let mobileHost = subdomain + ".m." + maindomain
          console.log("mobileHost", mobileHost)

          if (host.indexOf("localhost") == -1) {
            window.location.href = "//" + mobileHost
          }
        }
      }
    },
    async created() {
      let accountData = this.$userData.getAccount()
      if (accountData) {
        this.isSave = true
        this.account = accountData.account
        this.password = accountData.pwd
      } else {
        this.isSave = false
      }

      if (this.$userData.hasLogin()) {
        this.$store.commit("setLoginState", true)
        this.userImg = this.$userData.getAvatar()
      }

      this.initSchoolId()
    },
    methods: {
      showLoginModel: function () {
        console.log("show login")
        this.showLogin = true
      },
      goHistory: function () {
        this.$router.push({ name: "History" })
      },
      changeChanel: function (id) {
        this.$router.push({ name: "Home", query: { channel_id: id } })
      },
      toHome: function () {
        this.$router.push({ name: "Home" })
      },
      showSearchInput: function () {
        this.showInput = !this.showInput
      },
      findPwd: function () {
        let _self = this
        this.showLogin = false
        setTimeout(function () {
          _self.showFind = true
        }, 500)
      },
      sendSMS: function () {
        this.changeYzmTxt()
      },
      changeYzmTxt: function () {
        let second = 60
        let _self = this
        _self.timer = setInterval(function () {
          console.log("count down....")
          second--
          if (second <= 0) {
            _self.yzmTxt = "获取短信验证码"
            clearInterval(_self.timer)
          } else {
            _self.yzmTxt = `${second}秒后重发`
          }
        }, 1000)
      },
      requestData: async function () {
        let res = await this.$request.post("")
        if (res) {
          this.navData = res.navData
          this.logoImg = res.schoolData.logo
        }
      },
      doLogin: async function () {
        if (this.$util.isStrEmpty(this.account)) {
          this.$util.warn("请输入手机号")
          return
        }

        if (this.$util.isStrEmpty(this.password)) {
          this.$util.warn("请输入密码")
          return
        }

        if (this.isSave) {
          let data = {
            account: this.account,
            pwd: this.password,
          }
          this.$userData.saveAccount(data)
        } else {
          this.$userData.removeAccount()
        }

        let params = {
          username: this.account,
          password: this.password,
        }
        let res = await this.$request.post("/member/login", params)
        if (res) {
          this.$store.commit("setLoginState", true)
          this.userImg = res.avatar
          this.showLogin = false
          this.$userData.saveUserInfo(res)
          window.location.reload()
        }
      },
      loginOut: function (command) {
        if (command == "login-out") {
          this.$store.commit("setLoginState", false)
          this.$userData.removeUserInfo()
        }
      },
      requestSchoolId: async function () {
        let host = window.location.host
        if (host == "localhost:8080") {
          host = "test.boyameiyu.com"
        }

        let params = {
          domain: host,
        }
        let res = await this.$request.post("/system/check", params)
        if (res) {
          this.logoImg = res.logo
          localStorage.setItem("school_logo", res.logo)
          localStorage.setItem("school_id", res.school_id)
          this.$store.commit("setSchoolId", res.school_id)
          this.requestData()
        }
      },
      initSchoolId: async function () {
        let school_id = localStorage.getItem("school_id")
        let logo = localStorage.getItem("school_logo")
        if (this.$util.isStrEmpty(school_id) || this.$util.isStrEmpty(logo)) {
          this.requestSchoolId()
        } else {
          this.$store.commit("setSchoolId", school_id)
          this.requestData()
        }
      },
      onSubmit() {
        window.location.href = "/search?keyword=" + this.searchTxt
        // this.$router.push("/search?keyword=" + this.searchTxt)
      },
    },
  }
</script>

<style>
  @import "css/index.css";
  @import "css/iconfont.css";
</style>
