<template>
  <div>
    <el-breadcrumb class="nav" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        {{ crumbData.channel_name }}
      </el-breadcrumb-item>
      <el-breadcrumb-item>{{ crumbData.category_name }}</el-breadcrumb-item>
      <el-breadcrumb-item></el-breadcrumb-item>
    </el-breadcrumb>
    <div class="line-body"></div>
    <template v-if="showProgress">
      <div class="title-l">
        <span class="title-l-txt">视频进度：100%</span>
        <span class="title-l-txt" style="margin-left: 0.3rem">
          课件总数：21
        </span>
        <span class="title-l-txt" style="margin-left: 0.3rem">
          已完成数：21
        </span>
        <span class="title-l-txt" style="margin-left: 0.3rem">
          学习总时长：17:52:51
        </span>
      </div>
      <div class="line-body"></div>
    </template>

    <div class="card-class-l">
      <el-card
        class="clc"
        :body-style="{ padding: '0px' }"
        style="border: none"
        v-for="(item, index) in courseList"
        :key="index"
        :class="[index % 4 == 0 ? 'card-class' : 'card-class-m']"
        @click.native="toLesson(item.id)"
      >
        <el-image class="card-class-img" :src="item.image" fit="cover" />
        <div style="display: flex; flex-direction: column; flex: 1">
          <span class="card-class-title">{{ item.name }}</span>
          <span class="card-class-video-txt">视频</span>
        </div>
      </el-card>
    </div>
    <suspend ref="suspend"></suspend>
  </div>
</template>

<script>
  import Suspend from '@/components/Suspend'
  export default {
    name: 'cate',
    components: {
      Suspend,
    },
    data() {
      return {
        key: '',
        showProgress: false,
        category_id: '',
        channel_id: '',
        courseList: [],
        crumbData: {},
      }
    },
    methods: {
      toLesson: function (id) {
        this.$router.push({ name: 'Play', query: { id: id } })
      },
      requestData: async function () {
        let params = {
          channel_id: this.channel_id,
          category_id: this.category_id,
        }
        let res = await this.$request.postRaw('/course/getlist', params)
        if (res.success) {
          this.courseList = res.data
          this.crumbData = res.crumb[0]
        } else {
          this.$util.warn(res.msg)
        }
      },
    },
    mounted() {
      document.getElementById('header-line').style.visibility = 'visible'
    },
    created() {
      let query = this.$route.query
      this.category_id = query.id
      this.channel_id = query.channel_id
      this.requestData()
    },
  }
</script>

<style scoped>
  @import '../css/class.css';
  body {
    background-color: #7b7b7b;
  }
</style>
