<template>
  <div>
    <el-carousel :height="bannerHeight">
      <el-carousel-item
        class="b-l-item"
        v-for="(item, index) in bannerData"
        :key="index"
      >
        <el-image :src="item.image" fit="cover"></el-image>
      </el-carousel-item>
    </el-carousel>

    <div
      v-if="schoolData.trial_img"
      style="display: flex; flex-direction: column; align-items: center"
    >
      <img
        class="free-lesson-img center-in-parent"
        :src="schoolData.trial_img"
      />
    </div>

    <div class="card-s">
      <el-card
        class="clc"
        shadow="always"
        @click.native="next(item.type, item.id)"
        :body-style="{ padding: '0px' }"
        style="border: none"
        v-for="(item, index) in trialData"
        :key="index"
        :class="[index % 4 == 0 ? 'card-un-m' : 'card-m']"
      >
        <div class="card-l">
          <el-image class="card-img" :src="item.image" fit="cover" />
        </div>
        <div
          class="card-info-layout"
          style="
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 0.05rem;
          "
        >
          <span class="card-title center-in-parent">{{ item.title }}</span>
        </div>
      </el-card>
    </div>

    <!--    <div class="center-in-parent"-->
    <!--         style="margin-top: 0.33rem;display:flex; flex-direction: column; align-items: center;">-->
    <!--      <el-button @click="moreClass">更多内容<i class="el-icon-arrow-right el-icon&#45;&#45;right"></i></el-button>-->
    <!--    </div>-->

    <div
      v-if="schoolData.choicest_img"
      style="
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 0.33rem;
      "
    >
      <img class="free-lesson-img2" :src="schoolData.choicest_img" />
    </div>

    <div
      style="
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 0.54rem;
      "
    >
      <div
        class="clc"
        v-for="(item, index) in choicestData"
        :key="index"
        :class="[index % 4 == 0 ? 'lesson-img' : 'lesson-img-m']"
        @click="next(item.type, item.id)"
      >
        <el-card
          :body-style="{ padding: '0px' }"
          style="width: 100%; height: 100%; border: none"
        >
          <el-image
            class="lesson-img-item"
            :src="item.image"
            fit="fill"
          ></el-image>
        </el-card>
      </div>
    </div>

    <div class="">
      <a href="https://beian.miit.gov.cn" target="_blank">
        沪ICP备19029798号-1
      </a>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  //import HelloWorld from '@/components/HelloWorld.vue'

  export default {
    name: "Home",
    data() {
      return {
        bannerHeight: "2.55rem",
        channel_id: "",
        navData: [],
        choicestData: [],
        bannerData: [],
        trialData: [],
        schoolData: [],
      }
    },
    computed: {
      channel: function () {
        return this.$store.state.chanelId
      },
    },
    watch: {
      channel(newId) {
        this.channel_id = newId
        //this.requestData()
      },
    },
    methods: {
      next: function (type, id) {
        if (type == 2) {
          this.toClass(id)
        } else {
          this.toPlay(id)
        }
      },
      toPlay: function (id) {
        this.$router.push({
          name: "Play",
          query: {
            id: id,
          },
        })
      },
      toClass: function (id) {
        this.$router.push({ name: "Class", query: { id: id } })
      },
      moreClass: function () {
        console.log("....moreClass.....")
      },
      initBannerHeight: function () {
        let width = window.screen.width
        if (width > 1400) {
          this.bannerHeight = "2.55rem"
        } else {
          this.bannerHeight = "4.08rem"
        }
      },
      requestData: async function () {
        let params = {
          channel_id: this.channel_id,
        }
        let res = await this.$request.post("", params)
        if (res) {
          this.navData = res.navData
          this.choicestData = res.choicestData
          this.bannerData = res.bannerData
          this.trialData = res.trialData
          this.schoolData = res.schoolData
        }
      },
      requestSchoolId: async function () {
        let host = window.location.host
        if (host == "localhost:8080") {
          host = "test.boyameiyu.com"
        }

        let params = {
          domain: host,
        }
        let res = await this.$request.post("/system/check", params)
        if (res) {
          this.logoImg = res.logo
          localStorage.setItem("school_logo", res.logo)
          localStorage.setItem("school_id", res.school_id)
          this.$store.commit("setSchoolId", res.school_id)
          this.requestData()
        }
      },
      initSchoolId: async function () {
        let school_id = localStorage.getItem("school_id")
        let logo = localStorage.getItem("school_logo")
        if (this.$util.isStrEmpty(school_id) || this.$util.isStrEmpty(logo)) {
          this.requestSchoolId()
        } else {
          this.$store.commit("setSchoolId", school_id)
          this.logoImg = logo
          this.requestData()
        }
      },
    },
    created: async function () {
      this.initBannerHeight()
      let _self = this
      window.addEventListener("resize", function () {
        _self.initBannerHeight()
      })

      let query = this.$route.query
      this.channel_id = query.channel_id
      this.initSchoolId()
    },
    mounted() {
      document.getElementById("header-line").style.visibility = "hidden"
    },
  }
</script>

<style>
  @import "../css/home.css";
</style>
