import Vue from "vue"
import axios from 'axios'
import VueAxios from 'vue-axios'
import {Message} from "element-ui";
import qs from 'qs'
import userCache from './userCache'
import util from './util'

//axios.defaults.baseURL = '/api'
axios.defaults.baseURL = 'https://api.boyameiyu.com/api'
Vue.use(VueAxios, axios)

const get = function (url,params) {
    return getRequest(url, params, true)
}

const getRaw = function (url,params) {
    return getRequest(url, params,false)
}

const post = function (url,params) {
    return postRequest(url,params,true)
}

const postRaw = function (url,params) {
    return postRequest(url,params,false)
}

const getRequest = function(url,params,checkCode) {
    params = addBaseParams(params)
    let config = {
        params:params
    }
    return new Promise(resolve => {
        Vue.axios.get(url,config).then(res => {
            if (checkRes(res)){
                let response = res.data
                if (checkCode){
                    if (checkResCode(response)){
                        resolve(response.data)
                    }else {
                        warn(response.msg)
                        resolve(null)
                    }
                }else {
                    resolve(response)
                }
            }else {
                warn('网络异常')
                resolve(null)
            }
        })
    })
}

const postRequest = function (url, params, checkCode) {
    params = addBaseParams(params)
    let config = {
        headers:{ 'content-type': 'application/x-www-form-urlencoded' }
    }
    return new Promise(resolve =>{
        Vue.axios.post(url,qs.stringify(params),config).then(res => {
            if (checkRes(res)){
                let response = res.data
                if (checkCode){
                    if (checkResCode(response)){
                        resolve(response.data)
                    }else {
                        warn(response.msg)
                        resolve(null)
                    }
                }else {
                    resolve(response)
                }
            }else {
                warn('网络异常')
                resolve(null)
            }
        })
    })
}

const addBaseParams = function (params) {
    if (util.isEmpty(params)){
        params = {}
    }
    let token = userCache.getToken()
    if (token){
        params.token = token
    }
    let schoolId = localStorage.getItem('school_id')
    if (schoolId){
        params.school_id = schoolId
    }
    return params
}

const postJson = function (url, params) {
    return new Promise(resolve =>{
        Vue.axios.post(url, params).then(res => {
            console.log(res)
            resolve(null)
        })
    })
}

const checkRes = function (res) {
    if (res.status == 200 && res.data){
        return true
    }else {
        return false
    }
}

const checkResCode = function (data) {
    if (data && data.success){
        return true
    }else {
        return false
    }
}

const warn = function (msg) {
    Message({
        message: msg,
        type: 'warning'
    });
}

export default {
    get:get,
    getRaw:getRaw,
    post:post,
    postRaw:postRaw,
    postJson:postJson
}
