import Vue from "vue"
import VueRouter from "vue-router"
import Home from "../views/Home.vue"
import Class from "@/views/class"
import Search from "@/views/search"
import Lesson from "@/views/lesson"
import Play from "@/views/play"
//import Test from '@/views/test'
import History from "@/views/history"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    alias: "/index",
    name: "Home",
    component: Home,
    meta: {
      // 页面标题title
      title: "首页",
    },
  },
  {
    path: "/course/video/play",
    name: "Play",
    component: Play,
    meta: {
      // 页面标题title
      title: "视频播放页",
    },
  },
  {
    path: "/category",
    name: "Class",
    component: Class,
    meta: {
      // 页面标题title
      title: "内容列表",
    },
  },
  {
    path: "/search",
    name: "Search",
    component: Search,
    meta: {
      // 页面标题title
      title: "搜索结果",
    },
  },
  {
    path: "/course/detail",
    name: "Lesson",
    component: Lesson,
    meta: {
      // 页面标题title
      title: "内容详情",
    },
  },
  {
    path: "/history",
    name: "History",
    component: History,
    meta: {
      // 页面标题title
      title: "学习记录",
    },
  },
]
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

export default router
