<template>
  <div>
    <el-breadcrumb class="play-nav" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        {{ crumbData.channel_name }}
      </el-breadcrumb-item>
      <el-breadcrumb-item
        :to="{ path: '/category?id=' + crumbData.category_id }"
      >
        {{ crumbData.category_name }}
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="video-l">
      <video-player
        class="play-view vjs-custom-skin video-player"
        :options="playerOptions"
        @timeupdate="onPlayerTimeupdate($event)"
        @loadeddata="loadeddata($event)"
      ></video-player>
      <div class="play-menu" style="overflow-y: scroll">
        <div
          class="lesson-list-item"
          v-for="(item, index) in video_list"
          :key="index"
        >
          <div class="lesson-list-item-contain">
            <div style="display: flex; flex-direction: row">
              <img
                class="watch-img center-in-parent"
                src="../assets/un_watch.png"
              />
              <span class="watch-name center-in-parent clc">
                {{ item.name }}
              </span>
            </div>
            <div class="watch-time-layout">
              <template v-if="item.trial == '1'">
                <span class="watch-type center-in-parent">试看</span>
              </template>
              <span class="watch-time center-in-parent" style="">
                {{ formatSeconds(item.time) }}
              </span>
            </div>
          </div>
          <div
            style="background-color: #232323; height: 2px; width: 100%"
          ></div>
        </div>
      </div>
    </div>

    <div class="play-info-layout">
      <div class="play-info-layout-left">
        <div class="play-info-layout-top">
          <span class="center-in-parent play-info-layout-top-class-name">
            {{ videoData.name }}
          </span>
          <span class="center-in-parent play-info-layout-top-time-now">
            上次学习
          </span>
          <span class="center-in-parent play-info-layout-top-time-now-time">
            {{ formatSeconds(videoData.endtime) }}
          </span>
          <span class="center-in-parent play-info-layout-top-time-all">
            课程总时长
          </span>
          <span class="center-in-parent play-info-layout-top-time-all-time">
            {{ formatSeconds(videoData.time) }}
          </span>
        </div>
        <!--        <div class="play-info-layout-bottom">-->
        <!--          <span class="center-in-parent play-info-layout-bottom-txt">最近在学</span>-->
        <!--          <span class="center-in-parent play-info-layout-bottom-txt play-info-layout-bottom-margin-short">{{ videoData.study + '人'}}</span>-->
        <!--          <span class="center-in-parent play-info-layout-bottom-txt play-info-layout-bottom-margin-long">累计观看</span>-->
        <!--          <span class="center-in-parent play-info-layout-bottom-txt play-info-layout-bottom-margin-short">{{ videoData.read +'次' }}</span>-->
        <!--          <span class="center-in-parent play-info-layout-bottom-txt play-info-layout-bottom-margin-long">好评度</span>-->
        <!--          <span class="center-in-parent play-info-layout-bottom-txt play-info-layout-bottom-margin-short">{{videoData.rate}}</span>-->
        <!--          <div class="center-in-parent play-info-layout-bottom-line"></div>-->
        <!--          <img class="center-in-parent play-info-layout-bottom-phone" src="../assets/iphone.png" style="">-->
        <!--          <span class="center-in-parent play-info-layout-bottom-txt play-info-layout-bottom-margin-short">用手机看</span>-->
        <!--        </div>-->
      </div>
    </div>

    <div class="video-info-layout">
      <div class="video-info-left-layout">
        <el-tabs v-model="activeName">
          <el-tab-pane label="内容介绍" name="first">
            <div id="article" class="article-layout">
              {{ videoData.content }}
            </div>
          </el-tab-pane>
          <!--          <el-tab-pane label="自测" name="second">-->
          <!--            <div class="test-layout"></div>-->
          <!--          </el-tab-pane>-->

          <!--          <el-tab-pane label="提问" name="third" class="question">-->
          <!--            <div class="question-layout flex-div">-->
          <!--              <div class="flex-div-h question-course-complexity">-->
          <!--                <span class="course-complexity-title">难易度程度：</span>-->
          <!--                <div class="flex-div-h child-in-center course-complexity-item" v-for="(item,index) in courseComplexity" :key="index">{{item}}</div>-->
          <!--              </div>-->
          <!--              <el-input class="question-input" type="textarea" :autosize="{ minRows: 4, maxRows: 8}" placeholder="请提出您对于内容中感到疑惑的地方吧！"-->
          <!--                        v-model="questionContent" maxlength="2000" show-word-limit></el-input>-->
          <!--              <div class="flex-div-h submit-question">发表提问</div>-->
          <!--              <div class="line match-parent"></div>-->
          <!--              &lt;!&ndash; 问题列表 &ndash;&gt;-->
          <!--              <div class="flex-div match-parent" v-for="(item,index) in 2" :key="index">-->
          <!--                <div class="flex-div-h match-parent question-list-item">-->
          <!--                  <img class="question-icon" style="background-color: #495AFF" src="../assets/no-history.png" />-->
          <!--                  <div class="flex-div question-item-info">-->
          <!--                    <span class="question-item-id">1541513630</span>-->
          <!--                    <div class="flex-div-h question-item-complexity">有点难</div>-->
          <!--                    <div class="flex-div-h child-in-center question-item-question">-->
          <!--                      <span class="item-question-wen">问</span>-->
          <!--                      <span class="item-question-content">有安装包么</span>-->
          <!--                    </div>-->
          <!--                    <div class="flex-div-h match-parent time-reply-wrapper">-->
          <!--                      <span class="question-time" style="flex: 1">3天前</span>-->
          <!--                      <span class="question-reply">回复</span>-->
          <!--                      <img class="question-point" src="../assets/point-vertical.png">-->
          <!--                    </div>-->
          <!--                    &lt;!&ndash; 回复列表 &ndash;&gt;-->
          <!--                    <div class="flex-div-h match-parent reply-wrapper" v-for="(item,index) in 3" :key="index">-->
          <!--                      <img class="reply-icon" style="background-color: #495AFF" src="../assets/no-history.png">-->
          <!--                      <div class="flex-div reply-info">-->
          <!--                        <div class="flex-div-h">-->
          <!--                          <span class="reply-info-replier">老师</span>-->
          <!--                          <span class="reply-info-dianping flex-div-h">点评</span>-->
          <!--                          <span class="reply-info-huifu">回复</span>-->
          <!--                          <span class="reply-info-replier">1541513...</span>-->
          <!--                          <span class="reply-info-content">软件涉及版权问题，不提供的，自行网上搜索下载，继续加油</span>-->
          <!--                        </div>-->
          <!--                        <div class="flex-div-h match-parent time-reply-wrapper">-->
          <!--                          <span class="question-time">3天前</span>-->
          <!--                          <div style="flex: 1" class="huifu-wapper">-->
          <!--                            <span class="question-reply">回复</span>-->
          <!--                          </div>-->
          <!--                          <img class="question-point" src="../assets/point-vertical.png">-->
          <!--                        </div>-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                    <div class="line match-parent item-line"></div>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--              &lt;!&ndash; 带有背景色的分页 &ndash;&gt;-->
          <!--              <el-pagination background layout="prev, pager, next" :total="100" class="center-in-parent pagination"></el-pagination>-->
          <!--            </div>-->
          <!--          </el-tab-pane>-->
        </el-tabs>
      </div>

      <div class="video-info-right-layout">
        <span class="video-info-right-layout-title">相关内容</span>
        <div
          class="alike-list"
          v-for="(item, index) in relatedData"
          :key="index"
        >
          <div class="alike-item clc" @click="toLesson(item.id)">
            <el-image class="alike-item-img" :src="item.image" fit="cover" />
            <span class="alike-item-txt">{{ item.name }}</span>
          </div>
          <div class="alike-item-line" />
        </div>
      </div>
    </div>
    <suspend ref="suspend"></suspend>
  </div>
</template>
<script>
  import "video.js/dist/video-js.css"
  import "vue-video-player/src/custom-theme.css"
  import userCache from "@/js/userCache.js"
  import Suspend from "@/components/Suspend"
  import "videojs-contrib-hls"
  export default {
    components: {
      Suspend,
    },
    data() {
      return {
        // by yu
        courseComplexity: ["太简单", "简单", "适中", "有点难", "太难了"], // 内容复杂程度
        questionContent: "", // 提问的内容

        videoUrl: "http://api.boyameiyu.com/video/output.m3u8",
        activeName: "first",
        article: "",
        playerOptions: {
          playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度

          autoplay: false,

          controls: true,

          preload: "auto", //视频预加载

          muted: false, //默认情况下将会消除任何音频。

          loop: false, //导致视频一结束就重新开始

          language: "zh-CN",

          aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）

          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。

          sources: [
            {
              type: "application/x-mpegURL",

              src: "", //http://ivi.bupt.edu.cn/hls/cctv1hd.m3u8,http://ivi.bupt.edu.cn/hls/btv1hd.m3u8
            },
          ],

          hls: true,

          poster: "", //你的封面地址

          width: document.documentElement.clientWidth,

          notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        },
        courseId: "",
        videoId: "",
        endtime: "",
        videoData: {},
        crumbData: {},
        video_list: [],
        relatedData: [],
        timeupdate: 0,
      }
    },
    methods: {
      load: function () {},
      requestData: async function () {
        let token = userCache.getToken()

        let params = {
          id: this.videoId,
        }
        let res = await this.$request.postRaw("/video/detail", params)
        if (res.success) {
          this.videoData = res.data

          this.videoId = res.data.id
          this.courseId = res.data.course_id

          this.video_list = res.video_list
          this.relatedData = res.relatedData
          this.crumbData = res.crumb[0]
          this.playerOptions.poster = res.data.image
          document.getElementById("article").innerHTML = res.data.content

          if (this.videoData.trial == "0" && !token) {
            this.$emit("show-login")
            this.$util.warn("该视频不是可试看视频，请登录后查看")
            this.playerOptions.notSupportedMessage =
              "该视频不是可试看视频，请登录后查看"
          } else {
            this.playerOptions.sources[0].src = res.data.video
          }
        } else {
          this.$util.warn(res.msg)
        }
      },
      formatSeconds(value) {
        let result = parseInt(value)
        let h =
          Math.floor(result / 3600) < 10
            ? "0" + Math.floor(result / 3600)
            : Math.floor(result / 3600)
        let m =
          Math.floor((result / 60) % 60) < 10
            ? "0" + Math.floor((result / 60) % 60)
            : Math.floor((result / 60) % 60)
        let s =
          Math.floor(result % 60) < 10
            ? "0" + Math.floor(result % 60)
            : Math.floor(result % 60)

        let res = ""
        if (h !== "00") res += ` ${h} 时`
        if (m !== "00") res += ` ${m} 分`
        res += ` ${s} 秒`
        if (h == "00" && m == "00") {
          res = result + " 秒"
        }
        return res
      },
      toLesson: function (id) {
        this.$router.push({ name: "Lesson", query: { id: id } })
      },
      /* 获取视频播放进度 */
      onPlayerTimeupdate(player) {
        this.endtime = player.cache_.currentTime
        this.timeupdate++
        if (this.timeupdate == 2) {
          console.log(" timeout !", this.endtime)
          console.log("total:", player.cache_.duration)
          this.timeupdate = 0
          this.updateProgress(this.endtime)
        }
      },
      async updateProgress(endtime) {
        let params = {
          course_id: this.courseId,
          video_id: this.videoId,
          endtime: endtime,
        }
        let res = await this.$request.postRaw("/video/progress", params)
        if (res.success) {
          //
        }
      },
      loadeddata(player) {
        console.log("设置上次时间：", this.videoData.endtime)
        player.currentTime(this.videoData.endtime)
      },
    },
    created() {
      let query = this.$route.query
      this.videoId = query.id
      this.requestData()
    },
    mounted() {
      document.getElementById("header-line").style.visibility = "visible"
    },
  }
</script>
<style scoped>
  @import "../css/play.css";
  @import "../css/question.css";
</style>
