import VueCookies from 'vue-cookies'
import Vue from 'vue'
import util from './util'

Vue.use(VueCookies)

const saveAccount = function (data) {
    saveCookiesM('account', data.account)
    saveCookiesM('pwd', data.pwd)
}

const getAccount = function () {
    let account = getCookieData('account')
    let pwd = getCookieData('pwd')
    if (util.isEmpty(account) || util.isEmpty(pwd)) {
        return null
    } else {
        let data = {
            account: account,
            pwd: pwd
        }
        return data
    }
}

const removeAccount = function () {
    removeCookie('account')
    removeCookie('pwd')
}

const saveUserInfo = function (userData) {
    saveCookiesH('avatar',userData.avatar)
    saveCookiesH('token',userData.token)
}

const getAvatar = function () {
    return getCookieData('avatar')
}

const getToken = function () {
    return getCookieData('token')
}

const hasLogin = function () {
    return !util.isStrEmpty(getToken())
}

const removeUserInfo = function () {
    removeCookie('token')
    removeCookie('avatar')
}

const saveCookiesM = function (key, value) {
    Vue.$cookies.set(key, value, "1m");
}

const saveCookiesD = function (key, value) {
    Vue.$cookies.set(key, value, "1d");
}

const saveCookiesH = function (key, value) {
    Vue.$cookies.set(key, value, "1h");
}

const getCookieData = function (key) {
    return Vue.$cookies.get(key)
}

const removeCookie = function (key) {
    Vue.$cookies.remove(key)
}

export default {
    saveUserInfo: saveUserInfo,
    saveCookiesD:saveCookiesD,
    removeUserInfo: removeUserInfo,
    getToken: getToken,
    saveAccount: saveAccount,
    getAccount: getAccount,
    removeAccount: removeAccount,
    hasLogin:hasLogin,
    getAvatar:getAvatar
}
