<template>
  <div class="suspend-comp-container">
    <ul class="suspend-content">
      <li class="suspend-item">
        <i class="theme-customize-font icon-inner iconfont icon-dizhi1"></i>
        <span class="icon-name">返回主页</span>
        <div class="popover-link">点击图标可跳转</div>
        <a
          data-v-350fd6f8=""
          :href="host"
          target="_blank"
          class="jump-link"
        ></a>
      </li>
      <li class="suspend-item hide-item">
        <i class="theme-customize-font icon-inner iconfont icon-zhiding"></i>
        <span class="icon-name">置顶</span>
        <div class="theme-customize-font back-top"></div>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: "suspend",
    props: {
      msg: String,
    },
    data() {
      return {
        host: "//" + window.location.host,
      }
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .suspend-comp-container {
    position: fixed;
    right: 1rem;
    top: 38%;
    width: 68px;
    z-index: 8;
  }
  .suspend-content {
    width: 100%;
  }
  ul {
    list-style: none;
  }
  .suspend-content .suspend-item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 68px;
    height: 66px;
    border-radius: 2px;
    background-color: #fff;
  }
  .suspend-content .suspend-item .icon-inner {
    display: inline-block;
    width: 28px;
    height: 28px;
    font-size: 28px;
    color: #d1d7e1;
  }
  .suspend-content .suspend-item .icon-name {
    margin-top: 4px;
    line-height: 18px;
    font-size: 12px;
    color: #666;
  }
  .suspend-content .popover-link {
    display: none;
    position: absolute;
    right: 84px;
    top: 50%;
    transform: translateY(-50%);
    line-height: 20px;
    font-size: 14px;
    color: #999;
    white-space: nowrap;
  }

  .suspend-content .back-top,
  .suspend-content .jump-link {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  .suspend-content .suspend-item + .suspend-item {
    margin-top: 8px;
  }
  .suspend-content .hide-item {
    display: none;
  }
  .theme-customize-font {
    color: #ff9646 !important;
  }
  .iconfont {
    font-family: "iconfont" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .suspend-content .suspend-item .icon-inner:before {
    position: relative;
    top: -2px;
  }
</style>
