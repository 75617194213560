import {Message} from "element-ui";

export function isStrEmpty(obj) {
    if (typeof obj == "undefined" || obj == null || obj == "") {
        return true;
    } else {
        return false;
    }
}

export function isEmpty(obj) {
    if (typeof obj == "undefined" || obj == null) {
        return true;
    } else {
        return false;
    }
}

const warn = function (msg) {
    Message({
        message: msg,
        type: 'warning'
    });
}

const toLink = function (url) {
    window.location.href=url;
}

export default {
    isStrEmpty:isStrEmpty,
    isEmpty:isEmpty,
    warn:warn,
    toLink:toLink
}
